
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



































































































































































































































































































































































.contact-email {
  margin-bottom: 5px;
}

.icon {
  flex-shrink: 0;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.5rem;
  fill: #e84e0f;
}

.info {
  stroke: #e84e0f;
  fill: white;
}

.select.label {
  display: block;
}

.select select {
  width: 100%;
  padding: 0.5rem 0 0.3rem;
  font-size: 1.8rem;
  border: 0;
}

.feedback-message {
  width: 100%;
}

.phone-warning {
  background: #f8cab7;
}

.loading {
  margin-left: 1.5rem;
}

.phone {
  display: flex;
  width: 100%;
  color: $c-gray;

  ::v-deep .input {
    width: 100%;
  }
}

.right {
  flex-direction: row-reverse;
}

.phone__flag {
  flex-shrink: 0;
  width: 2rem;
  margin-right: 0.5rem;
}

.country-codes {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 1rem;
  padding: 0.5rem;
  border-bottom: 1px solid $c-gray-lighter;

  ::v-deep .placeholder {
    justify-content: flex-end;
  }

  ::v-deep .options {
    width: 9rem;
  }
}
